.keen-slider:not([data-keen-slider-disabled]){-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent;align-content:flex-start;display:flex;overflow:hidden;position:relative;touch-action:pan-y;-webkit-user-select:none;-moz-user-select:none;user-select:none;-khtml-user-select:none;width:100%}.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{min-height:100%;overflow:hidden;position:relative;width:100%}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse]{flex-direction:row-reverse}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v]{flex-wrap:wrap}
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

@font-face {
font-family: '__factor_3e3a4d';
src: url(/_next/static/media/dc38e39487c20b91-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__factor_Fallback_3e3a4d';src: local("Arial");ascent-override: 92.90%;descent-override: 24.45%;line-gap-override: 0.00%;size-adjust: 102.26%
}.__className_3e3a4d {font-family: '__factor_3e3a4d', '__factor_Fallback_3e3a4d'
}

@font-face {
font-family: '__writer_9fd3b9';
src: url(/_next/static/media/4bc5a75289faafac-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__writer_Fallback_9fd3b9';src: local("Arial");ascent-override: 105.98%;descent-override: 34.32%;line-gap-override: 0.00%;size-adjust: 99.07%
}.__className_9fd3b9 {font-family: '__writer_9fd3b9', '__writer_Fallback_9fd3b9';font-weight: 400
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_10f793';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e7f31236d01f7e0-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_10f793';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d3f1dc0f80085d05-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_10f793';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_10f793 {font-family: '__DM_Sans_10f793', '__DM_Sans_Fallback_10f793';font-weight: 400;font-style: normal
}

